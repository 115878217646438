import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';

import AuthenticatedRoute from '../../auth/components/AuthenticatedRoute';

import MainLayout from '../../main/layouts/main.layout';
import SimpleLayout from '../../main/layouts/simple.layout';

import LoginPage from '../../auth/login.page';
import UsersPage from '../../users/users.page';
import GroupsPage from '../../groups/groups.page';
import GroupDetailPage from '../../groups/group-detail.page';
import FormationPathsPage from '../../formation-paths/formation-paths.page';
import FormationPathDetailPage from '../../formation-paths/formation-path-detail.page';
import CoursesPage from '../../courses/courses.page';
import NotFoundPage from '../../main/notfound.page';
import CourseDetailPage from '../../courses/course-detail.page';
import CourseScoretablePage from '../../courses/course-scoretable.page';
import ReportPage from '../../admin/report.page';
import ReportUsersPage from '../../admin/report-users.page';
import CourseDesignPage from '../../admin/course-design/course-design.page';
import AreasPage from '../../areas/areas.page';
import PositionsPage from '../../positions/positions.page';
import RoutesReportPage from '../../reports/routes-report.page';
import CoursesReportPage from '../../reports/courses-report.page';
import ModulesReportPage from '../../reports/modules-report.page';
import ResponsesReportPage from '../../reports/responses-report.page';
import UserDetailPage from '../../users/user-detail.page';
import CourseSelectionPage from '../../admin/course-selection/course-selection.page';
import ExternalCoursesPage from '../../external-courses/external-courses.page';
import ExternalCourseDetailPage from '../../external-courses/external-course-detail.page';
import OrganizationalUnitsPage from '../../organizational-units/organizational-units.page';
import DashboardPage from '../../dashboard/dashboard.page';
import instructorsPage from '../../instructors/instructors.page';
import ValidationPage from '../../main/validation.page';
import CommunicationsPage from '../../communications/communications.page';
import communicationDetailPage from '../../communications/communication-detail.page';
import CertificationsPage from '../../certifications/certifications.page';
import ActivityReportPage from '../../reports/activity-report.page';
import PreregistrationsPage from '../../preregistrations/preregistrations.page';
import preregistrationDetailPage from '../../preregistrations/preregistration-detail.page';

class AppRouter extends PureComponent {
  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <Switch>
          <MainLayout exact path="/" title="Cursos" component={CoursesPage} />
          <MainLayout
            exact
            path="/users"
            title="Usuarios"
            component={UsersPage}
          />
          <MainLayout
            exact
            path="/users/:id"
            title="Perfil de usuario"
            component={UserDetailPage}
            showBack
          />
          <MainLayout exact path="/areas" title="Áreas" component={AreasPage} />
          <MainLayout
            exact
            path="/organizational-units"
            title="Unidades de negocio"
            component={OrganizationalUnitsPage}
          />
          <MainLayout
            exact
            path="/communications"
            title="Grupos / Audiencias"
            component={CommunicationsPage}
          />
          <MainLayout
            exact
            path="/communications/:id"
            title="Comunicación"
            showBack
            component={communicationDetailPage}
          />
          <MainLayout
            exact
            path="/positions"
            title="Cargos"
            component={PositionsPage}
          />
          <MainLayout
            exact
            path="/groups"
            title="Grupos"
            component={GroupsPage}
          />
          <MainLayout
            exact
            path="/groups/:id"
            title="Grupo"
            component={GroupDetailPage}
            showBack
          />
          <MainLayout
            exact
            path="/formation-paths"
            title="Planes de formación"
            component={FormationPathsPage}
          />
          <MainLayout
            exact
            path="/formation-paths/:id"
            title="Plan de formación"
            component={FormationPathDetailPage}
            showBack
          />
          <MainLayout
            exact
            path="/instructors"
            title="Formadores"
            component={instructorsPage}
          />
          <MainLayout
            exact
            path="/courses"
            title="Cursos"
            component={CoursesPage}
          />
          <MainLayout
            exact
            path="/courses/:id"
            title="Curso"
            component={CourseDetailPage}
            showBack
          />
          <MainLayout
            exact
            path="/courses/:id/design"
            title="Diseño de curso"
            component={CourseDesignPage}
            showBack
            hidePageHeader
          />
          <AuthenticatedRoute
            exact
            path="/courses/:id/scoretable"
            title="Tabla de posiciones"
            render={(matchProps) => <CourseScoretablePage {...matchProps} />}
          />
          <MainLayout
            exact
            path="/external-courses"
            title="Cursos"
            component={ExternalCoursesPage}
          />
          <MainLayout
            exact
            path="/external-courses/:id"
            title="Curso"
            component={ExternalCourseDetailPage}
            showBack
          />
          <MainLayout
            exact
            path="/dashboard"
            title="Indicadores"
            component={DashboardPage}
            hidePageHeader
          />
          <MainLayout
            exact
            path="/certifications"
            title="Certificaciones"
            component={CertificationsPage}
            hidePageHeader
          />
          <MainLayout
            exact
            path="/reports/routes"
            title="Reporte: Planes de formación"
            component={RoutesReportPage}
          />
          <MainLayout
            exact
            path="/reports/courses"
            title="Reporte: Cursos"
            component={CoursesReportPage}
          />
          <MainLayout
            exact
            path="/reports/modules"
            title="Reporte: Módulos"
            component={ModulesReportPage}
          />
          <MainLayout
            exact
            path="/reports/responses"
            title="Reporte: Respuestas Curso"
            component={ResponsesReportPage}
          />
          <MainLayout
            exact
            path="/reports/activity"
            title="Reporte: Usuarios activos"
            component={ActivityReportPage}
          />

          <MainLayout
            exact
            path="/admin/report"
            title="Reporte general"
            component={ReportPage}
            superadmin
          />

          <MainLayout
            exact
            path="/admin/report/users"
            title="Reporte de usuarios"
            component={ReportUsersPage}
            superadmin
          />

          <MainLayout
            exact
            path="/admin/courses"
            title="Todos los cursos"
            component={CourseSelectionPage}
            superadmin
          />
          <MainLayout
            exact
            path="/admin/courses/:id/design"
            title="Diseño de curso"
            component={CourseDesignPage}
            superadmin
            hidePageHeader
          />

          <MainLayout
            exact
            path="/preregistrations"
            title="Prerregistros"
            component={PreregistrationsPage}
          />

          <MainLayout
            exact
            path="/preregistrations/:id"
            title="Prerregistro"
            component={preregistrationDetailPage}
            showBack
          />

          <SimpleLayout path="/login" component={LoginPage} />
          <Route path="/validate/:hash" component={ValidationPage} />

          <Route component={NotFoundPage} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default AppRouter;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Descriptions,
  Row,
  Col,
  Divider,
  Button,
  message,
  Modal,
  Form,
  Input,
  Select,
  AutoComplete,
} from 'antd';

import styles from './preregistrationdetailpage.module.css';
import PreregistrationsService from '../core/services/preregistrations';
import DateDisplay from '../common/components/date';
import { CountryPhoneInput } from 'antd-country-phone-input';

const getCountryPhoneValueFromString = (val) => {
  if (val) {
    const parts = val.split(' ');
    return {
      code: parts.length >= 2 ? Number(parts[0]) : undefined,
      phone: parts.slice(1).join(''),
    };
  }

  return { short: 'CO' };
};

class PreregistrationDetailPage extends PureComponent {
  state = {
    preregistration: null,
    loading: false,
    custom_fields: [],
    areas: [],
    positions: [],
    ext_props_data: {},
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  onApprove = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      Modal.confirm({
        title: 'Estás seguro de aprobar el preregistro?',
        content:
          'Se creará el usuario en la plataforma y podrá empezar a usarla',
        onOk: () => this.changeState(true, values),
      });
    });
  };

  onReject = () => {
    Modal.confirm({
      title: 'Estás seguro de rechazar el preregistro?',
      content: 'El usuario no podrá acceder a la plataforma',
      onOk: () => this.changeState(false),
    });
  };

  changeState = async (approve, values) => {
    try {
      if (approve) {
        await PreregistrationsService.approve(this.props.match.params.id, {
          ...values,
          mobile: `+${values.mobile.code} ${values.mobile.phone}`,
        });
      } else {
        await PreregistrationsService.reject(this.props.match.params.id);
      }

      message.success(`Preregistro ${approve ? 'aprobado' : 'rechazado'}`);
      this.props.history.goBack();
    } catch (error) {
      message.error(
        `Ocurrio un error al ${approve ? 'aprobar' : 'rechazar'} el preregistro`
      );
    }
  };

  async fetchExtProps(index) {
    try {
      const data = await PreregistrationsService.getExtendedFieldValues(
        this.props.auth.user.tenant_id,
        index
      );

      this.setState({
        ext_props_data: { ...this.state.ext_props_data, [index]: data },
      });
    } catch (error) {
      message.error('Ocurrió un error al consultar los campos extendidos');
    }
  }

  async fetchLocations() {
    try {
      const data = await PreregistrationsService.getLocations(
        this.props.auth.user.tenant_id
      );
      this.setState({ locations: data });
    } catch (error) {
      message.error('Ocurrió un error al consultar las ubicaciones');
    }
  }

  async fetchAreas() {
    try {
      const data = await PreregistrationsService.getAreas(
        this.props.auth.user.tenant_id
      );
      this.setState({ areas: data });
    } catch (error) {
      message.error('Ocurrió un error al consultar las áreas');
    }
  }

  async fetchPositions() {
    try {
      const data = await PreregistrationsService.getPositions(
        this.props.auth.user.tenant_id
      );
      this.setState({ positions: data });
    } catch (error) {
      message.error('Ocurrió un error al consultar los cargos');
    }
  }

  async fetchPreregistration() {
    try {
      this.setState({ loading: true });
      const { data, custom_fields } = await PreregistrationsService.find(
        this.props.match.params.id
      );
      this.setState({ preregistration: data, custom_fields });

      for (let i = 0; i < custom_fields.length; i++) {
        await this.fetchExtProps(
          custom_fields[i].field_name.replace('extended_field', '')
        );
      }
    } catch (error) {
      message.error('Ocurrió un error al consultar el prerregistro');
    } finally {
      this.setState({ loading: false });
    }
  }

  validateUnique = async (rule, value, callback) => {
    if (!value) callback();
    if (
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      callback(); //Si no es un email válido no verificar si existe
    } else {
      var result = await PreregistrationsService.emailExists(
        this.props.auth.user.tenant_id,
        value
      );
      if (!result) callback();
      else callback('El correo electrónico ya existe');
    }
  };

  validateMobile = async (rule, value, callback) => {
    if (!value || !value.phone) callback();
    if (!value.phone.match(/^[\s0-9]+$/)) {
      callback('Ingresa un número de celular válido (solo números)');
    } else {
      const mobileValue = `+${value.code} ${(value.phone || '')
        .trim()
        .replace(/\s/g, '')}`;
      var result = await PreregistrationsService.mobileExists(
        this.props.auth.user.tenant_id,
        encodeURIComponent(mobileValue)
      );
      console.log('mobile result', result);
      if (!result) callback();
      else callback('Este número de celular ya está asociado a otro usuario');
    }
  };

  async componentDidMount() {
    await this.fetchPreregistration();
    await this.fetchAreas();
    await this.fetchPositions();
    await this.fetchLocations();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      areas,
      positions,
      locations,
      preregistration,
      custom_fields,
      ext_props_data,
    } = this.state;
    return (
      <div className="content-internal">
        <Form>
          <Row gutter={10}>
            <Col span={24}>
              <Descriptions
                className={styles.desc}
                title="Información del usuario"
                layout="vertical"
              >
                <Descriptions.Item label="Identificación">
                  <Form.Item>
                    {getFieldDecorator('identification_number', {
                      initialValue: preregistration?.identification_number,
                      rules: [],
                    })(<Input />)}
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Nombres">
                  <Form.Item>
                    {getFieldDecorator('first_name', {
                      initialValue: preregistration?.first_name,
                      rules: [
                        {
                          required: true,
                          message: 'Debes especificar el nombre',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Apellidos">
                  <Form.Item>
                    {getFieldDecorator('last_name', {
                      initialValue: preregistration?.last_name,
                      rules: [],
                    })(<Input />)}
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Correo electrónico">
                  <Form.Item>
                    {getFieldDecorator('email', {
                      initialValue: preregistration?.email,
                      rules: [
                        {
                          type: 'email',
                          message: 'Debes especificar un correo válido',
                        },
                        {
                          required: true,
                          message: 'Debes especificar el correo electrónico',
                        },
                        {
                          validator: this.validateUnique,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Celular">
                  <Form.Item>
                    {getFieldDecorator('mobile', {
                      initialValue: getCountryPhoneValueFromString(
                        preregistration?.mobile
                      ),
                      rules: [
                        {
                          validator: this.validateMobile,
                        },
                      ],
                    })(<CountryPhoneInput inline type="tel" />)}
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Área">
                  <Form.Item>
                    {getFieldDecorator('area_id', {
                      initialValue: preregistration?.area_id,
                      rules: [],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Seleccione el área"
                        notFoundContent="No hay resultados"
                      >
                        {(areas || []).map((a) => (
                          <Select.Option key={a.id} value={a.id} title={a.name}>
                            {a.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Cargo">
                  <Form.Item>
                    {getFieldDecorator('position_id', {
                      initialValue: preregistration?.position_id,
                      rules: [],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Seleccione el cargo"
                        notFoundContent="No hay resultados"
                      >
                        {(positions || []).map((a) => (
                          <Select.Option key={a.id} value={a.id} title={a.name}>
                            {a.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Ubicación">
                  <Form.Item>
                    {getFieldDecorator('location', {
                      initialValue: preregistration?.location,
                      rules: [],
                    })(
                      <AutoComplete
                        dataSource={locations?.filter((v) => !!v)}
                        filterOption
                      />
                    )}
                  </Form.Item>
                </Descriptions.Item>
                {custom_fields.map((cf) => (
                  <Descriptions.Item
                    key={cf.field_name}
                    label={cf.field_display_name}
                  >
                    <Form.Item>
                      {getFieldDecorator(cf.field_name, {
                        initialValue: preregistration?.[cf.field_name],
                        rules: [],
                      })(
                        <AutoComplete
                          dataSource={(
                            ext_props_data?.[
                              cf.field_name.replace('extended_field', '')
                            ] || []
                          ).filter((v) => !!v)}
                          filterOption
                        />
                      )}
                    </Form.Item>
                  </Descriptions.Item>
                ))}
                <Descriptions.Item className={styles.desc1} label="Fecha">
                  {preregistration?.created_at && (
                    <DateDisplay format="dd/MM/yyyy hh:mm aa">
                      {preregistration?.created_at}
                    </DateDisplay>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              <Descriptions title="Certificaciones" layout="vertical">
                {preregistration?.certifications?.map((c) => (
                  <Descriptions.Item key={c.id} label={c.certification_name}>
                    Fecha:{' '}
                    {c.date_completed ? (
                      <DateDisplay>{c.date_completed}</DateDisplay>
                    ) : (
                      '-'
                    )}
                    , Válido hasta:{' '}
                    {c.valid_through ? (
                      <DateDisplay>{c.valid_through}</DateDisplay>
                    ) : (
                      '-'
                    )}
                  </Descriptions.Item>
                ))}
              </Descriptions>
              <div className={styles.buttons}>
                <Button type="primary" icon="check" onClick={this.onApprove}>
                  Aprobar
                </Button>
                <Button type="secondary" icon="close" onClick={this.onReject}>
                  Rechazar
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.users, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PreregistrationDetailPage));
